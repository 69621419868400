@font-face {
  font-family: 'D-DIN';
  src: url('assets/fonts/D-DIN.eot') format('embedded-opentype'),
  url('assets/fonts/D-DIN.otf') format('opentype'),
  url('assets/fonts/D-DIN.woff') format('woff'),
  url('assets/fonts/D-DIN.ttf') format('truetype'),
  url('assets/fonts/D-DIN.svg#D-DIN') format('svg');
  font-weight: normal; font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'D-DIN-Bold';
  src: url('assets/fonts/D-DIN-Bold.eot') format('embedded-opentype'),
  url('assets/fonts/D-DIN-Bold.otf') format('opentype'),
  url('assets/fonts/D-DIN-Bold.woff') format('woff'),
  url('assets/fonts/D-DIN-Bold.ttf') format('truetype'),
  url('assets/fonts/D-DIN-Bold.svg#D-DIN') format('svg');
  font-weight: normal; font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'D-DINExp-Bold';
  src: url('assets/fonts/D-DINExp-Bold.eot') format('embedded-opentype'),
  url('assets/fonts/D-DINExp-Bold.otf') format('opentype'),
  url('assets/fonts/D-DINExp-Bold.woff') format('woff'),
  url('assets/fonts/D-DINExp-Bold.ttf') format('truetype'),
  url('assets/fonts/D-DINExp-Bold.svg#D-DIN') format('svg');
  font-weight: normal; font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open-Sans';
  src: 
  url('assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal; font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open-Sans-Bold';
  src: url('assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: normal; font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open-SansExp-Bold';
  src: url('assets/fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: normal; font-style: normal;
  font-display: swap;
}





