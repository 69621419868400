@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

:root {
    --investor-1bw-m-url   : url("assets/images/investors/1-bw.jpg");
    --investor-2bw-m-url   : url("assets/images/investors/2-bw.jpg") no-repeat center center;
    --investor-3bw-m-url   : url("assets/images/investors/3-bw.jpg");
    --investor-1color-m-url: url("assets/images/investors/1-color.jpg") no-repeat center center;
    --investor-2color-m-url: url("assets/images/investors/2-color.jpg");
    --investor-3color-m-url: url("assets/images/investors/3-color.jpg") no-repeat center center;

    --slategray : #1E384B;
    --brightteal: #3681A9;
    --darkteal  : #065675;
    --mustard   : #D6B564;
}

/* You can add global styles to this file, and also import other style files */
html,
body {
    overflow-x: hidden;
    color     : #274D62 !important;
}

body {
    font-family   : 'Open-Sans', sans-serif!important;
}

h1 {
    font-family: 'Open-Sans', sans-serif;
    font-size  : 36px !important;
    color      : var(--darkteal);
}

h2 {
    font-family: 'Open-Sans', sans-serif;
    font-size  : 24px !important;
    font-weight: 700;
    color      : #363333;
}

p {
    font-family: 'Open-Sans', sans-serif;
    font-size  : 15px; //!important;
    //color: #d4d4d4;
    opacity    : 1;
    font-weight: lighter;
}

strong {
    font-weight: bolder;
    font-family: 'Open-Sans', sans-serif;
    opacity    : 1;
}

strong {
    font-family: 'Open-Sans', sans-serif;
}

li {
    font-family: 'Open-Sans', sans-serif;
}

a {
    font-family: 'Open-Sans', sans-serif;
    color      : inherit !important;

    &.bold {
        font-family: "Open-Sans-Bold";
    }

    &:hover {
        text-decoration: none !important;
    }
}

.no-left-padding {
    padding-left: 0px !important;
}


.owl-stage {
    padding-left: 0px !important;
}

/*.owl-item:first-of-type {
    width: 200px !important;
}*/

ul.attention {
    padding    : 0;
    margin     : 0;
    white-space: nowrap;

    li {
        display      : inline-block;
        padding-right: 15px;
        list-style   : none;

        .ctr {
            position        : relative;
            height          : 4px;
            background-color: #f6f6f6;
        }

        p {
            position: relative;
            top     : 3px;

            &.nowrap {
                white-space: nowrap;
            }
        }
    }
}

.our-people-copy {
    padding-top: 60px;
    max-width  : 340px;

    p {
        font-family: "Open-Sans";
        font-size  : 14px; //!important;
        color      : #666;
        opacity    : 1;
        font-weight: lighter;
    }

    strong {
        font-weight: bolder;
        font-family: "Open-Sans-Bold";
        opacity    : 1;
    }
}

.normal-text {
    p {
        font-family: "Open-Sans";
        font-size  : 14px; //!important;
        color      : #8a8a8a;
        opacity    : 1;
        font-weight: lighter;
    }

    strong {
        font-weight: bolder;
        font-family: "Open-Sans-Bold";
        opacity    : 1;
    }
}

.about-us {
    line-height  : 1.5;
    max-width    : 60%;
    font-family  : "Open-Sans";
    font-size    : 15px; //!important;
    color        : #FFF !important;
    max-width    : 400px;
    font-weight  : lighter;
    padding-right: 10%;

    strong {
        font-weight: bolder;
        font-family: "Open-Sans-Bold";
        opacity    : 1;
    }
}

@media only screen and (max-width: 768px) {
    .about-us {
        padding-right: 20px;
    }
}

.ng-draggable {
    cursor: grab;
}

.ng-dragging {
    cursor: grabbing;
}

.highlight {
    font-family   : "Open-Sans-Bold";
    font-weight   : bold;
    text-transform: uppercase;
}

.small-p {
    font-size  : 20px;
    display    : block;
    line-height: 10px;
}

.marker {
    background     : url("assets/images/pin.png") no-repeat center center;
    background-size: contain;
}

.nomouseinterest {
    pointer-events: none;
}

.top-and-opaque {
    z-index: 10000;
    opacity: 1;
}

#desktop-menu {
    display       : block;
    pointer-events: none;
    position      : fixed;
    left          : 110px;
    right         : 0px;
    bottom        : 0px;
    top           : 0px;
    background    : #fff;
    opacity       : 0;
    transition    : all ease 0.3s;
    padding       : 80px 50px;
    z-index       : 99999999;

    &.is-open {
        opacity       : 1;
        pointer-events: all;
    }

    img {
        width     : 90%;
        max-width : 150px;
        margin-top: -4px;
    }
}

.our-people-stats {
    p.perc {
        color        : #000 !important;
        font-family  : "Open-Sans-Bold";
        font-size    : 60px !important;
        margin-bottom: 0px;
        opacity      : 1;
    }

    div.divider {
        background-color: var(--mustard);
        width           : 50px;
        height          : 8px;
    }

    p.label {
        color         : #000;
        font-family   : "Open-Sans-Bold";
        font-size     : 18px !important;
        text-transform: uppercase;
        max-width     : 160px;
        padding-top   : 15px;
        opacity       : 1;
    }

    .col {
        padding-right: 100px;
    }
}

.history-slide-text-mobile-0 {
    p {
        padding  : 30px 0;
        max-width: 80%;
    }
}

.history-slide-text-mobile-others {
    p {
        padding-top: 20%;
        color      : white !important;
        opacity    : 1;
    }
}

@media only screen and (max-width: 350px) {
    .history-slide-text-mobile-others {
        p {
            padding-top: 10%;
        }
    }
}

.history-slide-text-mobile-2 {
    p {
        padding  : 30px 0;
        max-width: 80%;
    }
}

.history-slide-text {
    p {
        color    : #fff !important;
        opacity  : 1;
        width    : 100%;
        max-width: 400px;
        font-size: 14px !important;
    }

    &.last p {
        max-width: 95%;
    }

    strong {
        font-weight: 700;
        font-family: "Open-Sans-Bold";
        opacity    : 1;
    }

    p.copy {
        color    : #fff !important;
        position : absolute;
        left     : 150px;
        top      : calc(50% + 100px);
        width    : 100%;
        max-width: 400px;
        font-size: 14px !important;

        &.enter-state-2 {
            animation: enter-state-2-p 0.6s linear 0s forwards;
        }
    }
}

.temp {
    color    : #fff;
    opacity  : 0.9;
    font-size: 14px;
}

.temp-m-mdd-top-stats {
    margin-top     : 10px;
    width          : 100%;
    position       : relative;
    right          : 0px;
    background     : var(--mdd-m-bg-top-url);
    background-size: cover;
    z-index        : 200;

    .overlay {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0.7);
        z-index   : 250;
    }

    .stats {
        color   : #fff;
        width   : 100%;
        padding : 20px 40px;
        position: relative;
        z-index : 10000;

        .perc {
            font-family: "Open-Sans-Bold";
            font-size  : 28px;
        }

        .divider {
            width     : 30px;
            height    : 4px;
            background: var(--mustard);
            margin    : 5px 0;
        }

        p.normal {
            color    : #fff;
            opacity  : 1;
            width    : 120px;
            max-width: 95%;
            margin   : 0;
            font-size: 13px;
        }

        p.bolder {
            text-transform: uppercase;
            color         : #fff;
            opacity       : 1;
        }
    }

    .footer {
        position        : relative;
        min-height      : 160px;
        background-color: #666;
        z-index         : 400;
        color           : #fff;
        padding         : 30px;

        p.title {
            font-family: "Open-Sans-Bold";
            font-size  : 28px;
            color      : #fff;
            opacity    : 1;
            margin     : 0;
        }

        .divider {
            width     : 30px;
            height    : 4px;
            background: var(--mustard);
            margin    : 5px 0;
        }

        p.text {
            color    : #fff;
            opacity  : 0.8;
            max-width: 95%;
            margin   : 0;
            font-size: 13px;
        }
    }
}

.temp-mdd-top-stats {
    position : absolute;
    top      : calc(50% - 85px);
    left     : 50%;
    transform: translate(-50%, -50%);
    z-index  : 300;
    color    : #fff;
    width    : 90%;
    padding  : 40px;

    .perc {
        font-family: "Open-Sans-Bold";
        font-size  : 28px;
    }

    .divider {
        width     : 30px;
        height    : 4px;
        background: var(--mustard);
        margin    : 5px 0;
    }

    p.normal {
        color    : #fff;
        opacity  : 0.8;
        max-width: 95%;
        margin   : 0;
        font-size: 13px;
    }

    p.bolder {
        text-transform: uppercase;
        color         : #fff;
        opacity       : 1;
    }
}

.temp-mdd-top-footer {
    position        : absolute;
    bottom          : 0px;
    height          : 160px;
    left            : 0;
    right           : 0;
    background-color: #666;
    z-index         : 400;
    color           : #fff;
    padding         : 30px;

    p.title {
        font-family: "Open-Sans-Bold";
        font-size  : 28px;
        color      : #fff;
        opacity    : 1;
        margin     : 0;
    }

    .divider {
        width     : 30px;
        height    : 4px;
        background: var(--mustard);
        margin    : 5px 0;
    }

    p.text {
        color    : #fff;
        opacity  : 0.8;
        max-width: 95%;
        margin   : 0;
        font-size: 13px;
    }
}

.temp-write-up-style {
    position     : relative;
    min-height   : 40px;
    padding-left : 20px;
    margin-bottom: 5px;

    p {
        max-width: 90%;
        color    : #000;
    }

    &::before {
        position        : absolute;
        content         : "";
        top             : 0;
        left            : 0;
        width           : 6px;
        height          : 100%;
        background-color: var(--brightteal);
    }
}

.temp-write-up-horizontal-style {
    .divider {
        width           : 50px;
        height          : 6px;
        background-color: var(--mustard);
    }

    p.top {
        margin        : 0;
        padding-bottom: 5px;
        min-height    : 50px;
        font-size     : 30px;
        color         : #000;
    }

    p.bottom {
        padding-top: 10px;
        font-size  : 14px;
        color      : #000;
    }
}

.copy .sub-copy {
    p {
        opacity  : 1;
        max-width: 90%;

        span {
            font-weight: 700;
            font-family: "Open-Sans-Bold";
            opacity    : 1;
        }

        strong {
            font-weight: 700;
            font-family: "Open-Sans-Bold";
            opacity    : 1;
        }
    }

    ul {
        padding    : 0;
        margin     : 0;
        margin-left: 15px;

        li {
            color    : #666;
            font-size: 15px !important;
            padding  : 0 0 10px 5px;
            opacity  : 1;
            max-width: 90%;
        }
    }
}

@media only screen and (max-width: 1400px) {
    .copy .sub-copy {
        ul {
            li {
                font-size: 13px !important;
            }
        }
    }
}

.we-do-more-detail.dynamic {
    padding : 100px 0 0 0;
    position: relative;

    .more {
        background-color: var(--brightteal);
        position        : absolute;
        bottom          : -54px;
        left            : -50px;
        padding-left    : 50px;
        height          : 50px;
        overflow        : hidden;
        transition      : all ease 0.4s;
        z-index         : 10000;
        display         : none;

        .header {
            width      : 100%;
            height     : 50px;
            margin-left: -10px;
            position   : relative;

            img {
                transition      : all ease 0.4s;
                position        : absolute;
                left            : 50%;
                top             : 14px;
                transform-origin: center center;
                transform       : translateX(-50%) rotate(180deg);
                width           : 16px;
            }
        }

        &.open {
            height: 430px;

            .header {
                img {
                    transform: translateX(-50%) rotate(0deg);
                }
            }

            ul {
                li {
                    opacity: 1;
                }
            }
        }

        ul {
            padding: 0;
            margin : 0;

            li {
                display       : inline-block;
                width         : 50%;
                padding       : 5px 5px;
                font-size     : 14px !important;
                vertical-align: text-top;
                color         : #fff;
                opacity       : 0;
                transition    : all ease 0.8s;
            }
        }
    }

    .copy-ctr {
        padding-right: 100px;
        padding-left : 50px;

        h1 {
            padding-bottom: 40px;
        }

        h2 {
            margin-top    : -40px;
            padding-bottom: 40px;
        }

        .writeup .row {
            padding: 20px 0;
        }

        ul {
            padding   : 0;
            margin    : 0 0 0 0px;
            list-style: none;

            li {
                color         : #666;
                padding-bottom: 15px;
                font-size     : 15px;

                &::before {
                    content       : "\2022";
                    /* Add content: \2022 is the CSS Code/unicode for a bullet */
                    color         : var(--brightteal);
                    /* Change the color */
                    font-weight   : bold;
                    /* If you want it to be bold */
                    display       : inline-block;
                    /* Needed to add space between the bullet and the text */
                    width         : 1em;
                    /* Also needed for space (tweak if needed) */
                    margin-left   : -1em;
                    /* Also needed for space (tweak if needed) */
                }
            }
        }
    }
}

.we-do-more-arrow {
    ul {
        margin : 0;
        padding: 0;
        display: inline;

        li {
            display: inline-block;

            &::before {
                content       : "\2022";
                /* Add content: \2022 is the CSS Code/unicode for a bullet */
                font-weight   : bold;
                /* If you want it to be bold */
                display       : none !important;
                /* Needed to add space between the bullet and the text */
                width         : 1em;
                /* Also needed for space (tweak if needed) */
                margin-left   : -1em;
                /* Also needed for space (tweak if needed) */
            }
        }
    }
}

.temp-social-styles {
    >div {
        position  : absolute;
        top       : 50%;
        transform : translateY(-50%);
        opacity   : 0;
        transition: all ease 0.6s;

        &.showcopy {
            opacity: 1;
        }
    }

    p {
        opacity  : 1;
        max-width: 90%;

        span {
            font-weight: 700;
            font-family: "Open-Sans-Bold";
            opacity    : 1;
        }

        strong {
            font-weight: 700;
            font-family: "Open-Sans-Bold";
            opacity    : 1;
        }
    }

    ul {
        padding    : 0;
        margin     : 0;
        margin-left: 15px;

        li {
            color    : #666;
            font-size: 15px !important;
            padding  : 0 0 10px 5px;
            opacity  : 1;
            max-width: 90%;
        }
    }
}

.culture-desktop-text {
    padding: 80px 60px;

    .text {
        position: relative;
    }

    h1 {
        color      : #fff;
        padding-top: 30px;
    }

    h2 {
        color      : #fff;
        padding    : 20px 0;
        line-height: 1.2;
    }

    p {
        color      : #fff;
        opacity    : 1;
        line-height: 1.5;
        /*font-size: 15px;*/
    }
}

.culture-mobile-text {
    padding: 80px 60px;

    h1 {
        color      : #fff;
        font-size  : 30pt !important;
        padding-top: 30px;
    }

    h2 {
        color    : #fff;
        padding  : 30px 0;
        font-size: 16pt !important;
    }

    p {
        color      : #fff;
        opacity    : 1;
        font-size  : 15px;
        line-height: 1.5;
    }

    .text {
        position: relative;
    }
}

.we-do-more-writeup {
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap  : break-word;
    /* Internet Explorer 5.5+ */
    opacity    : 1;
    line-height: 1.5;
    color      : #666;
    font-family: "Open-Sans";
    //font-size: 1rem; //!important;
    font-size  : 15px;
    opacity    : 1;
    font-weight: lighter;
}

.investor-left-copy {
    padding : 80px 80px 0 0;
    position: relative;

    ul {
        list-style: none;
        padding   : 0;
        margin    : 0 0 0 15px;

        li {
            color    : #666;
            padding  : 10px 0;
            font-size: 15px;

            &::before {
                content       : "\2022";
                /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color         : var(--brightteal);
                /* Change the color */
                font-weight   : bold;
                /* If you want it to be bold */
                display       : inline-block;
                /* Needed to add space between the bullet and the text */
                width         : 1em;
                /* Also needed for space (tweak if needed) */
                margin-left   : -1em;
                /* Also needed for space (tweak if needed) */
            }
        }
    }

    &::before {
        content         : "";
        position        : absolute;
        left            : -30px;
        width           : 8px;
        top             : 80px;
        bottom          : 0px;
        background-color: var(--brightteal);
    }

    .divider {
        margin-top      : 20px;
        width           : 80px;
        height          : 6px;
        background-color: var(--mustard);
    }
}

@media only screen and (max-width: 1400px) {
    .investor-left-copy {
        ul {
            li {
                font-size: 13px;
            }
        }
    }
}

.investor-full-width {
    .banner {
        width     : 100%;
        height    : 280px;
        position  : relative;
        transition: all linear 0.7s;

        p.title {
            position   : absolute;
            left       : 50%;
            top        : 50%;
            font-size  : 60px;
            color      : #fff;
            transform  : translate(-50%, -50%);
            font-family: "Open-Sans-Bold";
            transition : all ease-in-out 0.5s;
            white-space: nowrap;
        }

        div.copy {
            transition: all linear 0.7s;

            p {
                color: #fff;
            }
        }

        &:hover {
            p.title {
                transform: none;
                left     : 10%;
                top      : 70px;
                font-size: 46px;
            }
        }
    }

    .banner.banner-1 {
        background     : var(--investor-1color-m-url);
        background-size: cover;

        div.copy {
            position: absolute;
            top     : 145px;
            left    : 10%;
            right   : 10%;
            opacity : 0;

            div.divider {
                height          : 8px;
                width           : 100px;
                background-color: var(--mustard);
            }

            p {
                padding-top: 20px;
            }
        }

        &:hover {
            background-image: var(--investor-1bw-m-url);

            div.copy {
                opacity: 1;
            }
        }
    }

    .banner.banner-2 {
        background     : var(--investor-2bw-m-url);
        background-size: cover;

        div.copy {
            position: absolute;
            top     : 145px;
            left    : 10%;
            right   : 10%;
            opacity : 0;
        }

        &:hover {
            background-image: var(--investor-2color-m-url);

            div.copy {
                opacity: 1;
            }
        }
    }

    .banner.banner-3 {
        background     : var(--investor-3color-m-url);
        background-size: cover;

        div.copy {
            position: absolute;
            top     : 145px;
            left    : 10%;
            right   : 10%;
            opacity : 0;

            div.divider {
                height          : 8px;
                width           : 100px;
                background-color: var(--mustard);
            }

            p {
                padding-top: 20px;
            }
        }

        &:hover {
            background-image: var(--investor-3bw-m-url);

            div.copy {
                opacity: 1;
            }
        }
    }
}

.black-subtext-md {
    p {
        color    : #666;
        font-size: 15px !important;
    }
}

#mobile-menu {
    overflow-y    : scroll;
    display       : block;
    pointer-events: none;
    position      : fixed;
    left          : 0px;
    bottom        : 0px;
    top           : 60px;
    width         : 290px;
    background    : #fff;
    opacity       : 0;
    transition    : all ease 0.3s;
    padding       : 40px 30px 40px 0px;
    z-index       : 1000000;
    transform     : translateX(-100%);

    &.is-mobi-open {
        opacity       : 1;
        pointer-events: all;
        transform     : translateX(0);
    }
}

nav {
    position    : fixed;
    top         : 0;
    left        : 0;
    height      : 100vh;
    width       : 110px;
    border-right: 1px solid rgba(112, 112, 112, 0.25);

    .navicon {
        display      : block;
        cursor       : pointer;
        height       : 110px;
        width        : 110px;
        position     : relative;
        /*background : url("assets/images/main-nav.png") no-repeat center center;*/
        border-bottom: 1px solid rgba(112, 112, 112, 0.25);

        svg {
            position : absolute;
            top      : 50%;
            left     : 50%;
            transform: translate(-50%, -50%);
        }

        #line-top,
        #line-middle,
        #line-bottom {
            transition: all ease 0.4s;
        }

        &.is-open #line-top {
            transform       : rotate(41deg);
            transform-origin: top left;
        }

        &.is-open #line-middle {
            display: none;
        }

        &.is-open #line-bottom {
            transform       : rotate(-41deg);
            transform-origin: bottom left;
        }
    }

    .naviconmobile {
        display : none;
        position: relative;
        cursor  : pointer;

        svg {
            position : absolute;
            top      : 50%;
            left     : 50%;
            transform: translate(-50%, -50%);
        }

        #line-top,
        #line-middle,
        #line-bottom {
            transition: all ease 0.4s;
        }

        &.is-mobi-open #line-top {
            transform       : rotate(41deg);
            transform-origin: top left;
        }

        &.is-mobi-open #line-middle {
            display: none;
        }

        &.is-mobi-open #line-bottom {
            transform       : rotate(-41deg);
            transform-origin: bottom left;
        }
    }

    .logo {
        position       : absolute;
        bottom         : 0px;
        width          : 110px;
        height         : 110px;
        border-top     : 1px solid rgba(112, 112, 112, 0.25);
        background     : url("assets/images/logo.svg") no-repeat center center;
        background-size: 90px;
    }
}

section#page-content {
    padding-left: 110px;
}

.padding-container {
    padding-left: 50px;
}

.atf {
    height    : 100vh;
    min-height: 600px;
    position  : relative;
}

.atf-init {
    height: 100vh;
}

@media only screen and (min-width: 992px) {
    .atf-init {
        height: calc(100vh - 110px);
    }
}

.full-height {
    height: 100%;
}

.min-atf {
    min-height: 100vh;
}

.center-vertical {
    position : absolute;
    top      : 50%;
    transform: translateY(-50%);
}

.center-vertical-and-horizontal {
    position : absolute;
    top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);
}

.center-text {
    text-align: center;
}

.statebutton {
    background   : #f7f7f7;
    display      : inline-block;
    margin-right : 10px;
    padding      : 5px 15px;
    border-radius: 5px;
    cursor       : crosshair;
}

/*
  FORM STYLES
*/
input[type="text"],
input[type="email"],
textarea {
    width           : 100%;
    max-width       : 450px;
    background-color: #efefef;
    border          : none;
    padding         : 12px 30px;
    margin-bottom   : 15px;
    font-family     : "Open-Sans" !important;
    font-size       : 15px;
}

@media only screen and (max-width: 1400px) {
    h1 {
        font-size: 42px !important;
    }

    h2 {
        font-size: 20px !important;
    }

    p {
        //font-size: 14px !important;
        font-size: 13px;
    }
}

@media only screen and (max-width: 992px) {
    nav {
        height          : 60px;
        width           : 100%;
        border-right    : none;
        border-bottom   : 1px solid rgba(112, 112, 112, 0.25);
        background-color: #fff;
        z-index         : 900000;

        .navicon {
            height       : 60px;
            width        : 60px;
            background   : url("assets/images/main-nav.png") no-repeat center center;
            border-bottom: none;
            margin-left  : 50px;
            display      : none;
        }

        .naviconmobile {
            display      : block;
            height       : 60px;
            width        : 60px;
            border-bottom: none;
            margin-left  : 50px;
        }

        .logo {
            position       : absolute;
            bottom         : auto;
            top            : -30px;
            right          : 50px;
            width          : 150px;
            height         : 120px;
            border-top     : none;
            background     : url("assets/images/logo-f.png") no-repeat center center;
            background-size: contain;
        }
    }

    section#page-content {
        padding-left: 0px;
    }

    .padding-container {
        padding-left: 50px;
    }

    .our-people-copy {
        max-width     : 90%;
        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 768px) {
    .atf {
        min-height: auto;
        position  : relative;
    }

    .atf-init {
        height: auto;
    }

    .center-vertical {
        position : relative;
        top      : auto;
        transform: none;
    }

    section#page-content {
        /*padding-top: 150px;*/
    }

    .our-people-stats {
        p.perc {
            font-size: 50px !important;
        }

        p.label {
            font-size: 16px !important;
        }
    }

    .investor-full-width {
        .banner {
            height       : 200px;
            margin-bottom: 15px;
            position     : relative;
            transition   : all linear 0.7s;

            p.title {
                position   : absolute;
                left       : 50%;
                top        : 50%;
                font-size  : 60px;
                color      : #fff;
                transform  : translate(-50%, -50%);
                font-family: "Open-Sans-Bold";
                transition : all ease-in-out 0.5s;
                white-space: nowrap;
            }

            div.copy {
                transition: all linear 0.7s;

                p {
                    color    : #fff;
                    font-size: 13px;
                }
            }
        }

        .banner.banner-1 {
            background-image: url("assets/images/investors/1-bw.jpg");

            p.title {
                left     : 10%;
                top      : calc(50% - 90px);
                transform: none;
            }

            div.copy {
                top    : 50%;
                left   : 10%;
                right  : 10%;
                opacity: 1;
            }

            &:hover {
                p.title {
                    transform: none;
                    left     : 10%;
                    top      : calc(50% - 90px);
                    font-size: 60px;
                }
            }
        }

        .banner.banner-2 {
            background-image: url("assets/images/investors/2-color.jpg");

            p.title {
                left     : 10%;
                top      : calc(50% - 90px);
                transform: none;
            }

            div.copy {
                top    : 50%;
                left   : 10%;
                right  : 10%;
                opacity: 1;
            }

            &:hover {
                p.title {
                    transform: none;
                    left     : 10%;
                    top      : calc(50% - 90px);
                    font-size: 60px;
                }
            }
        }

        .banner.banner-3 {
            background-image: url("assets/images/investors/3-bw.jpg");

            p.title {
                left     : 10%;
                top      : calc(50% - 90px);
                transform: none;
            }

            div.copy {
                top    : 50%;
                left   : 10%;
                right  : 10%;
                opacity: 1;
            }

            &:hover {
                p.title {
                    transform: none;
                    left     : 10%;
                    top      : calc(50% - 90px);
                    font-size: 60px;
                }
            }
        }
    }
}

.investor-acquisition-left {

    //padding-left: 50px;
    h1 {
        padding-top: 150px;
    }

    p {
        padding: 10px 0 40px 0;
    }

    h2 {
        padding-bottom: 10px;
    }
}

.hide {
    display: none;
}

.our-business-banner {
    width           : 100%;
    background-color: #eee;
    height          : 180px;
    margin-bottom   : 30px;
    background-size : cover;
    position        : relative;
    transition      : all linear 0.4s;
    cursor          : pointer;

    .overlay {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0.5);
        opacity   : 0;
        transition: all linear 0.4s;

        p {
            color    : #fff;
            position : absolute;
            width    : 90%;
            top      : 50%;
            left     : 50%;
            transform: translate(-50%, -50%);
        }
    }

    .logo-ctr {
        position             : absolute;
        top                  : 50%;
        right                : 0;
        height               : 70px;
        width                : 150px;
        background-color     : #fff !important;
        transform            : translateY(-50%);
        transition           : all linear 0.4s;
        background-repeat    : no-repeat !important;
        background-position-x: center !important;
    }

    &:hover {
        .logo-ctr {
            opacity: 0;
        }

        .overlay {
            opacity: 1;
        }
    }
}

.bg-txt-sz {
    font-size: 52px !important;
}

.md-txt-sz {
    font-size  : 20px !important;
    line-height: 22px;
}

@media only screen and (max-width: 1400px) {
    .bg-txt-sz {
        font-size: 46px !important;
    }

    .md-txt-sz {
        font-size  : 18px !important;
        line-height: 20px;
    }
}

@media only screen and (max-width: 1200px) {
    .bg-txt-sz {
        font-size: 36px !important;
    }

    .md-txt-sz {
        font-size  : 16px !important;
        line-height: 18px;
    }
}

@media only screen and (max-width: 576px) {
    nav {
        height: 60px;
        width : 100%;

        .navicon {
            height     : 60px;
            width      : 60px;
            margin-left: 20px;
        }

        .logo {
            position: absolute;
            bottom  : auto;
            top     : -11px;
            right   : 20px;
            width   : 110px;
            height  : 80px;
        }
    }

    nav {
        .naviconmobile {
            margin-left: 20px;
        }
    }

    section#page-content {
        /*padding-top: 100px;*/
    }

    .padding-container {
        padding-left: 20px;
    }

    .our-people-stats {
        p.perc {
            font-size: 46px !important;
        }

        p.label {
            font-size: 13px !important;
            width    : 100px;
        }
    }
}

/* ANDRE - STYLE ADDITIONS */
.cc-message {
    font-family: "Open-Sans" !important;
}

.cc-window,
.cc-bottom {
    background-color: #666 !important;
}

.cc-bottom {
    font-size: 14px !important;
}

.socialcopy p {
    color: #666;
}

section.mobile-ticker {
    padding-right: 20px;

    p {
        color: #666;
    }

    ul {
        padding    : 0;
        margin     : 0;
        margin-left: 15px;

        li {
            color      : #666;
            font-size  : 14px !important;
            line-height: 16px !important;
            padding    : 0 0 6px 3px;
            opacity    : 1;
            max-width  : 90%;
        }
    }
}

.article {
    ul {
        padding     : 0;
        margin      : 0;
        padding-left: 10px;
    }
}

/* ANDRE: Bamboo HR Styles */
.BambooHR-ATS-board h2 {
    display: none;
}

ul.BambooHR-ATS-Department-List {
    margin    : 0;
    padding   : 0;
    list-style: none;

    li {
        list-style: none;
    }
}

ul.BambooHR-ATS-Jobs-List {
    padding: 0;
    margin : 0;

    li.BambooHR-ATS-Jobs-Item {
        margin-bottom: 15px;
        position     : relative;
        max-width    : calc(100% - 170px);

        a {
            font-family     : "Open-Sans-Bold";
            background-color: #f3f3f3;
            display         : inline-block;
            height          : 100%;
            width           : calc(100% + 150px);
            padding         : 35px 20px 60px 20px;
        }

        &::after {
            content         : "View";
            pointer-events  : none;
            padding-left    : 45px;
            padding-top     : 10px;
            color           : #fff;
            position        : absolute;
            width           : 120px;
            font-size       : 13px;
            height          : 40px;
            right           : -130px;
            top             : 50%;
            transform       : translateY(-50%);
            background-color: var(--brightteal);
            cursor          : pointer;
        }
    }
}

.BambooHR-ATS-Department-Header {
    font-family   : "Open-Sans-Bold";
    font-size     : 30px;
    padding-bottom: 20px;
}

span.BambooHR-ATS-Location {
    margin-left: 20px;
    font-family: "Open-Sans";
    position   : absolute;
    left       : 0px;
    bottom     : 40px;
}

@media only screen and (max-width: 576px) {
    ul.BambooHR-ATS-Jobs-List {
        li.BambooHR-ATS-Jobs-Item {
            &::after {
                display: none;
            }
        }
    }
}

/****** HOME MENU *****/

#sidebar-main.hide {
    display: none;
}

#page-content.nopadding {
    padding-left: 0px;
}

.home-menu-container {
    padding-top   : 45px;
    padding-bottom: 7px;
    z-index       : 1000000;
    position      : absolute;
    top           : 0px;
    height        : 110px;
    width         : calc(100% - 100px) !important;

    img {
        margin-top : -3px;
        margin-left: 30px;
    }
}



.home-heading {
    padding-bottom: 30px !important;
    font-size     : 24px !important;
    max-width     : 400px;
}

@media only screen and (min-width: 1300px) {
    .home-heading {
        max-width: 500px;
    }

    .about-us {
        max-width  : 500px;
        padding-top: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .home-heading {
        max-width: 90%;
    }

    .about-us {
        max-width: 90%;
    }
}

.home-menu {
    padding-bottom: 10px;

    >ul {
        display       : flex;
        align-items   : center;
        flex-direction: row;
        padding       : 0;
        margin        : 0 auto;

        a,
        li {
            flex-grow      : 1;
            list-style-type: none;
            font-size      : 15px;
        }

        >a {
            max-height: 50px;
        }

        >a>li {
            padding-bottom: 30px;
            margin-top    : 3px;
        }

        li {
            position: relative;

            ul {
                position        : absolute;
                margin          : 10px 0 0 0;
                padding         : 0;
                box-shadow      : 1px 1px 10px #F2f2f2;
                border          : 1px solid #ccc;
                padding         : 10px 15px;
                opacity         : 0;
                pointer-events  : none;
                transition      : all ease 0.6s;
                background-color: #fff;

                &.shown {
                    opacity       : 1;
                    pointer-events: all;
                }

                li {
                    color      : #666 !important;
                    font-weight: normal !important;
                    font-size  : 14px !important;
                    line-height: 26px !important;
                    position   : relative;
                    white-space: nowrap;

                    &::before {
                        content   : '';
                        position  : absolute;
                        left      : -10px;
                        width     : 4px;
                        height    : 10px;
                        top       : 8px;
                        background: #3681A9;
                        opacity   : 0;
                        transition: all linear 0.5s;
                    }

                    &:hover::before {
                        opacity: 1;
                    }
                }

            }
        }

        li.active-menu {
            color      : #005FAE;
            font-weight: 700;
        }
    }
}

.home-logo {
    width: 160px;
}

.spacer-desktop {
    width : 100%;
    height: 108px;
}

@media only screen and (max-width: 1400px) {
    .home-logo {
        width: 160px;
    }
}

@media only screen and (max-width: 1200px) {
    .home-logo {
        width: 160px;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 768px) {
    p {
        font-size: 12px;
    }

    h1 {
        font-size: 36px !important;
    }

    .normal-text p {
        font-size: 12px;
    }

    app-breadcrumb span {
        font-size: 13px !important;
    }

    app-inline-breadcrumb span {
        font-size: 13px !important;
    }

    app-arrow-button p {
        font-size: 13px !important;
    }

    .history-slide-text p {
        font-size: 12px !important;
    }

    #slide-background .three p {
        left: 50px !important;
    }

    p.twentyten {
        font-size: 100px !important;
    }

    app-our-people-chart svg {
        margin-top: -70px !important;
    }

    p.ourvisioncopy {
        padding-top: 30px !important;
    }

    .copy.socialcopy {
        li {
            font-size: 100px !important;
        }
    }

    .culture-desktop-text h2 {
        font-size: 16px !important;
    }

    .full-height {
        min-height: 600px !important;
    }

    .bottom-container.wwd {
        height: 280px !important;
    }

    .left .speciality-container p {
        font-size: 11px !important;
    }

    .left .speciality-container h2 {
        padding-top: 10px !important;
    }

    .left .speciality-container div.row {
        padding: 20px 0 !important;

        p {
            margin-bottom: 10px !important;
        }
    }

    app-speciality-summary .ctr {
        top: 60% !important;

        .image {
            width : 400px !important;
            height: 350px !important;
        }
    }

    .back.acq {
        display: none;
    }

    p.quote {
        font-size  : 23px !important;
        line-height: 28px !important;
    }

    h1.excessive {
        padding-top: 60px !important;
    }

    .writeup.ob {
        font-size: 12px !important;
        padding  : 20px 0 !important;
    }

    .tags ul li {
        font-size: 12px;
    }

    app-interested-in-businesses p.writeup {
        font-size: 12px !important;
        height   : 140px !important;
    }

    app-interested-in-businesses app-arrow-button p {
        font-size: 12px !important;
    }

    .copy.wdm {
        padding      : 15px 0 !important;
        margin-bottom: 0 !important;
    }

    .wdmo .atf {
        min-height: 600px !important;
    }

    .wdm ul.main li {
        font-size: 12px !important;
        padding  : 2px 0 !important;
    }

    .wdm .investors {
        bottom: 0px !important;
    }

    .we-do-more-writeup {
        line-height: 1.5;
        font-size  : 12px;
    }

    .md-txt-sz {
        font-size  : 14px !important;
        line-height: 17px !important;
    }

    .bg-txt-sz {
        font-size: 32px !important;
    }

    .we-do-more-detail.dynamic .copy-ctr ul li {
        font-size: 12px;
    }

    /****** HOME MENU *****/
    .home-heading {
        font-size: 20px !important;
        max-width: 350px;
    }

    .home-logo {
        width      : 110px;
        padding-top: 5px;
    }

    .home-menu {
        top: 10px;

        >ul {

            a,
            li {
                font-size: 12px;
            }

            li {
                position: relative;

                ul {

                    li {
                        font-size  : 12px !important;
                        line-height: 26px !important;

                    }

                }
            }

        }
    }
}


/***** TESTIMONIAL *****/
.copy-t {
    position  : absolute;
    top       : 50%;
    left      : 50%;
    width     : 100%;
    max-width : 400px;
    transform : translate(-50%, -50%);
    color     : #fff;
    text-align: center;
    padding   : 10px;

    p {
        line-height: 25px;
    }
}



/* DARWIN - CMS - VIDEOS */

.ql-video {
    width : 500px;
    height: 320px;
}

.owl-theme .owl-nav [class*=owl-] {
    background: #4A9EDC !important;
    min-width : 60px;
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
    text-align : left !important;
    margin-left: 205px;
}

@media only screen and (max-width: 500px) {
    .ql-video {
        width : 300px;
        height: 180px;
    }
}